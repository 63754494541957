var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-xl-12"},[_c('v-tooltip',{attrs:{"top":"","z-index":"2000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.api)?_c('button',_vm._g(_vm._b({staticClass:"btn btn-primary font-weight-bold px-4 py-2 my-1 m-5 font-size-3",on:{"click":_vm.encerrarSala}},'button',attrs,false),on),[_c('i',{staticClass:"material-icons valign-wrapper"},[_vm._v("stop")])]):_vm._e()]}}])},[_c('span',[_vm._v("Encerra a Sala")])]),_c('v-tooltip',{attrs:{"top":"","z-index":"2000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.api)?_c('button',_vm._g(_vm._b({staticClass:"btn btn-primary font-weight-bold px-4 py-2 my-1 m-5 font-size-3",class:{'red darken-4': _vm.volume=='volume_off'},on:{"click":_vm.toggleAudio}},'button',attrs,false),on),[_c('i',{staticClass:"material-icons valign-wrapper"},[_vm._v(_vm._s(_vm.volume))])]):_vm._e()]}}])},[(_vm.volume=='volume_up')?_c('span',[_vm._v("Desliga o Microfone")]):_c('span',[_vm._v("Liga o Microfone")])]),_c('v-tooltip',{attrs:{"top":"","z-index":"2000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.api)?_c('button',_vm._g(_vm._b({staticClass:"btn btn-primary font-weight-bold px-4 py-2 my-1 m-5 font-size-3",class:{'red darken-4': _vm.camera=='videocam_off'},on:{"click":_vm.toggleVideo}},'button',attrs,false),on),[_c('i',{staticClass:"material-icons valign-wrapper"},[_vm._v(_vm._s(_vm.camera))])]):_vm._e()]}}])},[(_vm.camera=='videocam')?_c('span',[_vm._v("Desliga a Câmera")]):_c('span',[_vm._v("Liga a Câmera")])]),_c('v-tooltip',{attrs:{"top":"","z-index":"2000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.api)?_c('button',_vm._g(_vm._b({staticClass:"btn btn-primary font-weight-bold px-4 py-2 my-1 m-5 font-size-3",on:{"click":function($event){return _vm.fullScreen()}}},'button',attrs,false),on),[_c('i',{staticClass:"material-icons valign-wrapper"},[_vm._v("fullscreen")])]):_vm._e()]}}])},[_c('span',[_vm._v("Tela cheia")])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{ref:"meet",staticStyle:{"height":"80vh"},attrs:{"id":"meet"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }