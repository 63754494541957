<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row text-center">
      <div class="col-xl-12">
        <v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-if="api" v-bind="attrs" v-on="on"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 m-5 font-size-3"
                    @click="encerrarSala">
              <i class="material-icons valign-wrapper">stop</i>
            </button>
          </template>
          <span>Encerra a Sala</span>
        </v-tooltip>
        <v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-if="api" v-bind="attrs" v-on="on"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 m-5 font-size-3"
                    :class="{'red darken-4': volume=='volume_off'}" @click="toggleAudio">
              <i class="material-icons valign-wrapper">{{volume}}</i>
            </button>
          </template>
          <span v-if="volume=='volume_up'">Desliga o Microfone</span>
          <span v-else>Liga o Microfone</span>
        </v-tooltip>
        <v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-if="api" v-bind="attrs" v-on="on"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 m-5 font-size-3"
                    :class="{'red darken-4': camera=='videocam_off'}"
                    @click="toggleVideo">
              <i class="material-icons valign-wrapper">{{camera}}</i>
            </button>
          </template>
          <span v-if="camera=='videocam'">Desliga a Câmera</span>
          <span v-else>Liga a Câmera</span>
        </v-tooltip>
        <v-tooltip top z-index="2000">
          <template v-slot:activator="{ on, attrs }">
            <button v-if="api" v-bind="attrs" v-on="on"
                    class="btn btn-primary font-weight-bold px-4 py-2 my-1 m-5 font-size-3"
                    @click="fullScreen()">
              <i class="material-icons valign-wrapper">fullscreen</i>
            </button>
          </template>
          <span>Tela cheia</span>
        </v-tooltip>
      </div>
    </div>

    <!--Container principal da imagem-->
    <div class="row">
      <div class="col-xl-12">
        <div id="meet" style="height: 80vh" ref="meet"></div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
	import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
	import {ADD_FILE, LOGOUT, SET_SALA, VERIFY_AUTH, FECHA_SALA} from "../../../core/services/store/auth.module";
	import ApiService from "@/core/services/api.service";
	import {DOMAIN, BACK} from '@/config';

	export default {
		name: "convidado",
		components: {},
		data() {
			return {
				api: null,
				volume: 'volume_up',
				camera: 'videocam',
				texto: '',
				nomeSala: null,
				sala: null,
				permiteNotificacoes: false,
        participante: null,
			}
		},
		mounted() {

			/*var ua = navigator.userAgent.toLowerCase();
			var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
			if(isAndroid) {
				alert('android');
				window.location = 'http://android.davidwalsh.name';
			}*/
			//var ua = navigator.userAgent.toLowerCase();
			//alert(navigator);
			//console.log(navigator);

			/*this.$http
        .post(`${BACK}navegadores`,{userAgent: ua})*/

			/*var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
			var isXiaomi = ua.indexOf("xiaomi") > -1
			if (isAndroid || isXiaomi) {
				var firefox = typeof InstallTrigger !== 'undefined';
				var chrome = (!!window.chrome && !!window.chrome.webstore) || navigator.appVersion.indexOf('Chrome') > -1;
				var safari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;

				if ((!safari && !chrome && !firefox) || isXiaomi) {
					if (confirm('Ir para navegador compátivel?')) {
						window.location.href = 'https://play.google.com/store/apps/details?id=com.android.chrome&hl=pt_BR';
					}
				}
			}*/



			//ApiService.setHeader();
			this.$store.dispatch(SET_BREADCRUMB, [{title: "Convidado"}]);
			this.participante = this.$store.getters.getParticipante;
			this.sala = this.$store.getters.getSala;
			if (!this.participante) {
				this.encerrarSala();
			}

			this.nomeSala = this.sala.sala;

			if ("Notification" in window) {
				//alert(Notification.permission);
				if (Notification.permission === "granted") {
					this.permiteNotificacoes = true;
					this.$store.commit('setPermiteNotificacoes', true);
					//alert(Notification.permission);
					// If it's okay let's create a notification
					//var notification = new Notification("Hi there!");
				} else if (Notification.permission !== 'denied') {
					//alert(Notification.permission);
					Notification.requestPermission()
						.then(perm => {
							if (perm === "granted") {
								this.permiteNotificacoes = true;
								this.$store.commit('setPermiteNotificacoes', true);
							} else {
								//console.log(perm);
								this.permiteNotificacoes = false;
								this.$store.commit('setPermiteNotificacoes', false);
							}

						})
						.catch()
				}
			}


			let params = this.$store.getters.getParametros;
			this.channel = this.$pusher.subscribe(`${params[0].ibge}-arquivo-${this.participante.id}`);
      this.channel.bind('arquivo-enviado', this.trataEventoArquivoRecebido);
			this.channel = this.$pusher.subscribe(`${params[0].ibge}-sala-${this.participante.sala.id}`);
      this.channel.bind('arquivo-enviado', this.trataEventoArquivoRecebido);
      this.channel.bind('sala-encerrada', this.trataEventoSalaEncerrada);
      /*this.channel = this.$pusher.subscribe(`sala-${this.participante.sala.id}`);*/
      this.entraConferencia();
		},
		computed: {},
		methods: {
			fullScreen() {
				var videoElement = document.getElementById("meet");

				if (!document.mozFullScreen && !document.webkitFullScreen) {
					if (videoElement.mozRequestFullScreen) {
						videoElement.mozRequestFullScreen();

					} else {
						videoElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
					}

				} else {

					if (document.mozCancelFullScreen) {
						document.mozCancelFullScreen();
					} else {
						document.webkitCancelFullScreen();
					}
				}
			},
			getOptions() {
			  var info = {};
			  if (this.participante.paciente){
			    info.displayName = this.participante.paciente.no_usuario;
        } else {
          info.displayName = 'Convidado';
        }
				return {
					roomName: this.nomeSala,
          userInfo: info,
					//width: 400,
					//height: 600,
					noSsl: false,
					parentNode: document.querySelector('#meet'),
					interfaceConfigOverwrite: {
						SHOW_JITSI_WATERMARK: false,
						JITSI_WATERMARK_LINK: 'https://jitsi.org',
						SHOW_WATERMARK_FOR_GUESTS: false,
						SHOW_BRAND_WATERMARK: false,
						BRAND_WATERMARK_LINK: '',

						TOOLBAR_BUTTONS: [
							//'microphone',
							//'camera',
							//'closedcaptions',
							//'desktop',
							'fullscreen',
							'//fodeviceselection',
							'hangup',
							//'profile',
							//'chat',
							//'recording',
							//'livestreaming',
							//'etherpad',
							//'sharedvideo',
							'settings',
							//'raisehand',
							//'videoquality',
							//'filmstrip',
							//'invite',
							//'feedback',
							//'stats',
							//'shortcuts',
							//'tileview',
							//'videobackgroundblur',
							//'download',
							//'help',
							'mute-everyone',
							//'e2ee',
							//'security'
						],
					},
					configOverwrite: {defaultLanguage: 'ptBR', disableDeepLinking: true},
				};
			},
			entraConferencia() {
			const options = this.getOptions();

				/*navigator.getUserMedia(
            // constraints
            {
                video: true,
                audio: true
            },

            // successCallback
            function (localMediaStream) {
                //var video = document.querySelector('video');
                //video.src = window.URL.createObjectURL(localMediaStream);
                //video.onloadedmetadata = function (e) {
                    // Do something with the video here.
                //};
            },

            // errorCallback
            function (err) {
                if (err === PERMISSION_DENIED) {
                    // Explain why you need permission and how to update the permission setting
                    console.log(err);
                }
            }
        );*/

				this.api = new JitsiMeetExternalAPI(DOMAIN, options);

				setTimeout(() => {
					this.api.on('tileViewChanged ', (ret) => {
						console('11111111111111111111111111111111111111111')
						console.log(ret);
					});
					this.api.addEventListener('endpointTextMessageReceived', () => {
						this.$store
							.dispatch(LOGOUT)
							.then(() => this.$router.push({name: "login"}));
					});
					this.api.addEventListener('videoConferenceJoined', (response) => {
					});
					this.api.addEventListener('participantJoined ', (response) => {
						console.log('Mais um participante');
						console.log(response);
					});
					this.api.addEventListener('incomingMessage', e => {
						console.log(e);
					});
					this.api.addEventListener('readyToClose', this.readyToClose);
					this.api.addEventListener('participantRoleChanged', e => {
						console.log('Mudou o Role');
						/*console.log(e);
						this.api.executeCommand('password', '1234');*/
					});
					this.api.isAudioMuted().then(muted => {
						if (muted) this.volume = 'volume_off';
						else this.volume = 'volume_up';
					});
					this.api.isVideoMuted().then(muted => {
						if (muted) this.camera = 'videocam_off';
						else this.camera = 'videocam';
						//console.log('Video Muted');
					});
					//custJitsiAPI.on('readyToClose', () => {console.log('call hung up fron ON event');});
				}, 3000);
			},
			encerrarSala() {
				let params = this.$store.getters.getParametros;
				this.$pusher.unsubscribe(`${params.ibge}-arquivo-${this.participante.id}`);
				this.$pusher.unsubscribe(`${params.ibge}-sala-${this.participante.sala.id}`);

				this.api.dispose();
				this.api = null;
				this.$store
					.dispatch(LOGOUT)
					.then(() => this.$router.push({name: "login"}));
			},
			toggleAudio() {
				this.volume = this.volume == 'volume_off' ? 'volume_up' : 'volume_off';
				this.api.executeCommand('toggleAudio');
			},
			toggleVideo() {
				this.camera = this.volume == 'videocam_off' ? 'videocam' : 'videocam_off';
				this.api.executeCommand('toggleVideo');
			},
			toggleFilmStrip() {
				this.api.executeCommand('toggleFilmStrip');
			},
			toggleChat() {
				this.api.executeCommand('toggleChat');
			},
			hangup() {
				this.api.executeCommand('hangup');
			},
			mudaEmail() {
				this.api.executeCommand('email', 'example@example.com');
			},
			sendTexto() {
				this.api.executeCommand('sendEndpointTextMessage', 'receiverParticipantId', 'text');
			},
			setVideoQuality() {
				this.api.executeCommand('setVideoQuality', 720);
			},
			readyToClose() {
				console.log('hanghup acionado!');
				this.api.dispose();
				this.api = null;
			},
			toast(titulo, mensagem, variant, toaster) {
				if (toaster == null) toaster = 'b-toaster-top-right';
				this.$bvToast.toast(mensagem, {
					title: titulo,
					variant: variant,
					toaster: toaster,
					solid: true
				});

			},
			notifyme(message, titulo) {
				if (this.permiteNotificacoes) {
					var notification = new Notification(message);
				}
			},
			notificacaoArquivoRecebido(arquivo) {
				console.log(arquivo);
				let mensagem = `Arquivo Recebido\nAcesse a Sala usando a chave:!`;
				let titulo = 'Arquivo Recebido!'
				this.notifyme(mensagem, titulo);
				this.toast(titulo, mensagem, 'info');
			},
			trataEventoArquivoRecebido(data) {
				this.notificacaoArquivoRecebido(data.arquivo);
				this.$store.dispatch(ADD_FILE, data.arquivo);
			},
			trataEventoSalaEncerrada(data) {
        this.toast('Sala Encerrada!', 'A Sala foi encerrada pelo anfitrião', 'danger');
			  setTimeout(() => this.encerrarSala(), 3000);

			}
		},
	};
</script>
